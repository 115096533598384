import {createRouter, createWebHistory} from 'vue-router';


//注册 登录
import userLogin from "../views/userLogin";
import userSMSLogin from "../views/userSMSLogin";
import forgotPassword from "../views/forgotPassword";
import userRegister from "../views/userRegister";

//我的主页
import myHome from "../views/myHome";
//任务主页
import taskReward from "../views/taskReward";
//邀请主页
import agentPromotion from "../views/agentPromotion";
//游戏历史
import myPlayList from "../views/myPlayList";
import myPlayList2 from "../views/myPlayList2";
import myPlayListParity from "../views/myPlayListParity";
import myPlayListMine from "../views/myPlayListMine";
import myPlayListWheel from "../views/myPlayListWheel";
import myPlayListAndar from "../views/myPlayListAndar";
import myPlayListDice from "../views/myPlayListDice";
import gameHistoryList from "../views/gameHistoryList";
//红包
import receiveBonus from "../views/receiveBonus";
import shareBonus from "../views/shareBonus";
//银行卡
import bankCard from "../views/bankCard";
import addBankCard from "../views/addBankCard";
import complaintsSuggestions from "../views/complaintsSuggestions";
import complaintsSuggestions2 from "../views/complaintsSuggestions2";
import addComplaints from "../views/addComplaints";
import withdrawalBalance from "../views/withdrawalBalance";
import withdrawalHistoryList from "../views/withdrawalHistoryList";
import rechargeBalance from "../views/rechargeBalance";
import rechargeHistoryList from "../views/rechargeHistoryList";
import rechargeComplain from "../views/rechargeComplain";
import rechargeUSDT from "../views/rechargeUSDT";
import rechargeUsdtList from "../views/rechargeUsdtList";
import resetPassword from "../views/resetPassword";
import transactionsHistoryList from "../views/transactionsHistoryList";
import cashHistoryList from "../views/cashHistoryList";
import envelopesCenter from "../views/envelopesCenter";
import envelopesDetail from "../views/envelopesDetail";
import agentGrowth from "../views/agentGrowth";
import agentGrowthPlus from "../views/agentGrowthPlus";
import promotionPartner from "../views/promotionPartner";
import setAvatar from "../views/setAvatar";
import parityHome from "@/views/parityHome";
import gameLobby from "@/views/gameLobby";
import agentSupportPlan from "@/views/agentSupportPlan";
import mineHome from "@/views/mineHome";
import cashTransfer from "@/views/cashTransfer";
import wheelHome from "@/views/wheelHome";

import firstRechargeBonus from "@/views/firstRechargeBonus";
import inviteCoupon from "@/views/inviteCoupon";
import andarHome from "@/views/andarHome";
import rechargeC2C from "@/views/rechargeC2C";

import crash2Home from "@/views/crash2Home";

import helpSupport from "@/views/helpSupport";
import ticket2Detail from "@/views/ticket2Detail";

import diceHome from "@/views/diceHome";
import inviteHistoryBonus from "@/views/inviteHistoryBonus";

import playGame from "@/views/playGame";
import liveGame from "@/views/liveGame";
import gamesList from "@/views/gamesList";
import referBonus from "@/views/referBonus";
import activityHistoryList from "../views/activityHistoryList";

import vipDetails from "../views/vipDetails";
import lobbyGameHistoryList from "../views/lobbyGameHistoryList";

const routes = [
    {
        path: '/',
        name: 'index',
        component: gameLobby,
    },
    {
        path: '/lobby',
        name: 'gameLobby',
        component: gameLobby,
        meta: {
            // istoken:true,
            tx: 1
        }
    },
    {
        path: '/rechargeC2C',
        name: 'rechargeC2C',
        component: rechargeC2C,
        meta: {
            tx: 1
        }
    },
    {
        path: '/agentPromotion',
        name: 'agentPromotion',
        component: agentPromotion,
        meta: {
            istoken: true,
            tx: 1
        }
    },
    {
        path: '/agentSupportPlan',
        name: 'agentSupportPlan',
        component: agentSupportPlan,
        meta: {
            istoken: true,
            tx: 1
        }
    },
    {
        path: '/crash2',
        name: 'crash2Home',
        component: crash2Home,
        meta: {
            istoken: true,
            tx: 1
        }
    },
    {
        path: '/myPlayList',
        name: 'myPlayList',
        component: myPlayList,
        meta: {
            tx: 2
        }
    },
    {
        path: '/myPlayList2',
        name: 'myPlayList2',
        component: myPlayList2,
        meta: {
            tx: 2
        }
    },
    {
        path: '/myPlayListParity',
        name: 'myPlayListParity',
        component: myPlayListParity,
        meta: {
            tx: 2
        } 
    },
    {
        path: '/myPlayListDice',
        name: 'myPlayListDice',
        component: myPlayListDice,
        meta: {
            tx: 2
        }
    },
    {
        path: '/myPlayListMine',
        name: 'myPlayListMine',
        component: myPlayListMine,
        meta: {
            tx: 2
        }
    },
    {
        path: '/myPlayListAndar',
        name: 'myPlayListAndar',
        component: myPlayListAndar,
        meta: {
            tx: 2
        }
    },
    {
        path: '/myPlayListWheel',
        name: 'myPlayListWheel',
        component: myPlayListWheel,
        meta: {
            tx: 2
        }
    },
    {
        path: '/gameHistoryList',
        name: 'gameHistoryList',
        component: gameHistoryList,
        meta: {
            tx: 2
        }
    },
    {
        path: '/myHome',
        name: 'myHome',
        component: myHome,
        meta: {
            istoken: true,
            tx: 1
        }
    },
    {
        path: '/taskReward',
        name: 'taskReward',
        component: taskReward,
        meta: {
            istoken: true,
            tx: 1
        }
    },
    {
        path: '/rechargeBalance',
        name: 'rechargeBalance',
        component: rechargeBalance,
        meta: {
            istoken: true,
            tx: 1
        }
    },
    {
        path: '/rechargeUSDT',
        name: 'rechargeUSDT',
        component: rechargeUSDT,
        meta: {
            istoken: true,
            tx: 1
        }
    },
    {
        path: '/rechargeUsdtList',
        name: 'rechargeUsdtList',
        component: rechargeUsdtList,
        meta: {
            istoken: true,
            tx: 1
        }
    },
    {
        path: '/rechargeHistoryList',
        name: 'rechargeHistoryList',
        component: rechargeHistoryList,
        meta: {
            istoken: true,
            tx: 1
        }
    },
    {
        path: '/withdrawalBalance',
        name: 'withdrawalBalance',
        component: withdrawalBalance,
        meta: {
            istoken: true,
            tx: 1
        }
    },
    {
        path: '/withdrawalHistoryList',
        name: 'withdrawalHistoryList',
        component: withdrawalHistoryList,
        meta: {
            istoken: true,
            tx: 1
        }
    },
    {
        path: '/rechargeComplain',
        name: 'rechargeComplain',
        component: rechargeComplain,
        meta: {
            istoken: true,
            tx: 1
        }
    },
    {
        path: '/cashTransfer',
        name: 'cashTransfer',
        component: cashTransfer,
        meta: {
            istoken: true,
            tx: 1
        }
    },
    {
        path: '/userLogin',
        name: 'userLogin',
        component: userLogin,
    },
    {
        path: '/userSMSLogin',
        name: 'userSMSLogin',
        component: userSMSLogin,
        meta: {
            tx: 2
        }
    },
    {
        path: '/forgotPassword',
        name: 'forgotPassword',
        component: forgotPassword,
        meta: {
            tx: 2
        }
    },
    {
        path: '/userRegister',
        name: 'userRegister',
        component: userRegister,
        meta: {
            tx: 2
        }
    },
    {
        path: '/receiveBonus',
        name: 'receiveBonus',
        component: receiveBonus,
        meta: {
            tx: 1
        }
    },
    {
        path: '/shareBonus',
        name: 'shareBonus',
        component: shareBonus,
        meta: {
            tx: 1
        }
    },
    {
        path: '/bankCard',
        name: 'bankCard',
        component: bankCard,
        meta: {
            istoken: true,
            tx: 2
        }
    },
    {
        path: '/addBankCard',
        name: 'addBankCard',
        component: addBankCard,
        meta: {
            istoken: true,
            tx: 2
        }
    },
    {
        path: '/complaintsSuggestions',
        name: 'complaintsSuggestions',
        component: complaintsSuggestions,
        meta: {
            istoken: true,
            tx: 2
        }
    },
    {
        path: '/complaintsSuggestions2',
        name: 'complaintsSuggestions2',
        component: complaintsSuggestions2,
        meta: {
            istoken: true,
            tx: 2
        }
    },
    {
        path: '/addComplaints',
        name: 'addComplaints',
        component: addComplaints,
        meta: {
            istoken: true,
            tx: 2
        }
    },
    {
        path: '/resetPassword',
        name: 'resetPassword',
        component: resetPassword,
        meta: {
            istoken: true,
            tx: 2
        }
    },
    {
        path: '/transactionsHistoryList',
        name: 'transactionsHistoryList',
        component: transactionsHistoryList,
        meta: {
            istoken: true,
            tx: 2
        }
    },
    {
        path: '/cashHistoryList',
        name: 'cashHistoryList',
        component: cashHistoryList,
        meta: {
            istoken: true,
            tx: 2
        }
    },
    {
        path: '/envelopesCenter',
        name: 'envelopesCenter',
        component: envelopesCenter,
        meta: {
            istoken: true,
            tx: 2
        }
    },
    {
        path: '/envelopesDetail',
        name: 'envelopesDetail',
        component: envelopesDetail,
        meta: {
            istoken: true,
            tx: 2
        }
    },
    {
        path: '/setAvatar',
        name: 'setAvatar',
        component: setAvatar,
        meta: {
            istoken: true,
            tx: 2
        }
    },
    {
        path: '/agentrgGrowth',
        name: 'agentGrowth',
        component: agentGrowth,
        meta: {
            istoken: true,
            tx: 2
        }
    },
    {
        path: '/agentGrowthPlus',
        name: 'agentGrowthPlus',
        component: agentGrowthPlus,
        meta: {
            istoken: true,
            tx: 2
        }
    },
    {
        path: '/promotionPartner',
        name: 'promotionPartner',
        component: promotionPartner,
        meta: {
            istoken: true,
            tx: 2
        }
    },
    {
        path: '/parity',
        name: 'parityHome',
        component: parityHome,
        meta: {
            istoken: true,
            tx: 1
        }
    },
    {
        path: '/mine',
        name: 'mineHome',
        component: mineHome,
        meta: {
            istoken: true,
            tx: 1
        }
    },
    {
        path: '/wheel',
        name: 'wheelHome',
        component: wheelHome,
        meta: {
            istoken: true,
            tx: 1
        }
    },
    {
        path: '/firstRechargeBonus',
        name: 'firstRechargeBonus',
        component: firstRechargeBonus,
        meta: {
            tx: 1
        }
    },
    {
        path: '/inviteCoupon',
        name: 'inviteCoupon',
        component: inviteCoupon,
        meta: {
            tx: 1
        }
    },
    {
        path: '/andar',
        name: 'andarHome',
        component: andarHome,
        meta: {
            istoken: true,
            tx: 1
        }
    },
    {
        path: '/helpSupport',
        name: 'helpSupport',
        component: helpSupport,
        meta: {
            tx: 2
        }
    },
    {
        path: '/ticket2Detail',
        name: 'ticket2Detail',
        component: ticket2Detail,
        meta: {
            tx: 2
        }
    },
    {
        path: '/dice',
        name: 'diceHome',
        component: diceHome,
        meta: {
            istoken: true,
            tx: 1
        }
    },
    {
        path: '/historyBonus',
        name: 'inviteHistoryBonus',
        component: inviteHistoryBonus,
        meta: {
            tx: 1
        }
    },
    {
        path: '/playGame',
        name: 'GAME',
        component: playGame,
        meta: {
            tx: 2
        }
    },
    {
        path: '/liveGame',
        name: 'Live',
        component: liveGame,
        meta: {
            tx: 2
        }
    },
    {
        path: '/gamesList',
        name: 'gamesList',
        component: gamesList,
        meta: {
            istoken: true,
            tx: 1
        }
    },
    {
        path: '/referBonus',
        name: 'referBonus',
        component: referBonus,
        meta: {
            tx: 1
        }
    },
    {
        path: '/activityHistoryList',
        name: 'activityHistoryList',
        component: activityHistoryList,
        meta: {
            istoken: true,
            tx: 2
        }
    },
    {
        path: '/vipDetails',
        name: 'vipDetails',
        component: vipDetails,
        meta: {
            istoken: true,
            tx: 1
        }
    },
    {
        path: '/lobbyGameHistoryList',
        name: 'gameOrder',
        component: lobbyGameHistoryList,
        meta: {
            istoken: true,
            tx: 2
        }
    },

    
    
    
]
const router = createRouter({
    history: createWebHistory(),
    routes,
})
router.beforeEach((to, from, next) => {
    //document.title = to.meta.title
    if (!localStorage.getItem('token') && to.meta.istoken === true) {
        router.push('./userRegister')
        return
    }
    next()
})

export default router;

